<script>
import Layout from "../../layouts/main";
import Order from "../../components/order";
import Service from "../../components/service";
import Invoice from "../../components/invoice";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import {profile,queryContact,saveContact} from '@/apis/client'
import {lookup,getLookupText} from '@/apis/common'
/**
 * Tabs & accordions component
 */
export default {
  page: {
    title: "客户管理",
    meta: [{ name: "description", content: appConfig.description }]
  },
  props:{
    clientNo:{
      type: String,
      required: true
    }
  },
  components: { Layout ,Order,Service,Invoice},
  data() {
    return {
      title: "客户管理",
      client:{},
      showProfile:false,
      lookupData:{
        clientSource:[],
        companyType:[],
        industry:[]
      },
      contactForm:{
        contact:"",
        department:"",
        title:"",
        mobile:"",
        email:""
      },
      showContactForm:false,
      text: `
         Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat salvia cillum iphone. Seitan aliquip quis cardigan american apparel, butcher voluptate nisi qui.
        `,
      content: `Food truck fixie locavore, accusamus mcsweeney's marfa nulla single-origin coffee squid. Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan four loko farm-to-table craft beer twee. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts ullamco ad vinyl cillum PBR. Homo nostrud organic, assumenda labore aesthetic magna delectus mollit. Keytar helvetica VHS salvia yr, vero magna velit sapiente labore stumptown. Vegan fanny pack odio cillum wes anderson 8-bit.`
    };
  },
  computed:{
    clientSource(){
      return getLookupText(this.lookupData.clientSource,this.client.clientSourece).join(",")
    },
    industry(){
      return getLookupText(this.lookupData.industry,this.client.industry).join(",")
    },
    companyType(){
      return getLookupText(this.lookupData.companyType,this.client.companyType).join(",")
    },
    clientTags(){
      if(this.client.tags!=undefined && this.client.tags!=""){
        return this.client.tags.split(",");
      }else{
        return []
      }
    }
  },
  watch: {
    client: function () {
        document.title = this.client.clientName + "-" + "客户管理"
    }
  },
  created(){
    this.init()
    this.initClient()
  },
  mounted() {
  },
  methods: {
    async init(){
      await lookup({"type":"COMPANY-TYPE,CLIENT-SOURCE,INDUSTRY"}).then((res)=>{
        this.lookupData.companyType = res.data["COMPANY-TYPE"];
        this.lookupData.clientSource = res.data["CLIENT-SOURCE"];
        this.lookupData.industry = res.data["INDUSTRY"];
      })
    },
    async initClient(){
      await profile(this.clientNo).then((res)=>{
          this.client = res.data
          this.showProfile = true;
      }).catch((res)=>{
        if(res.code==500){
          Swal.fire("System Error", "客户不存在", "error").then(()=>{
          this.$router.push({ path: '/client' });
        });
        }
      })
    },
    loadContact(){
      queryContact({clientId:this.client.clientId}).then((res)=>{
        this.client.contacts = res.data;
      })
    },
    handleNewContact(){
      this.contactForm = {
        clientId: this.client.clientId,
        contact:"",
        department:"",
        title:"",
        mobile:"",
        email:""
      },
      this.showContactForm = true;
    },
    handleEditContact(curContact){
      this.contactForm = curContact
      this.showContactForm = true;
    },
    handleSaveContact(){
      saveContact(this.contactForm).then(()=>{
        this.loadContact();
      })
    },
    handleCancleContact(){
      this.showContactForm = false;
    },
  }
};
</script>

<template>
  <Layout>
    <div class="row mt-4">
      <div class="col-xl-12" v-if="showProfile">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <h4 class="card-title">客户详情</h4>
                <p class="card-title-desc">
                  {{client.clientName}}
                </p>
              </div>
              <div class="col-sm-6">
                <div class="float-end d-none d-md-block">
                  <a href="/client" class="btn btn-success">返回</a>
                </div>
              </div>
            </div>
            <b-tabs content-class="p-3">
              <b-tab active class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">基本信息</span>
                </template>
                <div class="mb-0">
                  <div class="row">
                        <div class="col-md-6">
                            <div class="infoField">公司名称(当地语言)</div>
                            <div class="infoData">{{ client.clientName }}</div>
                        </div>
                        <div class="col-md-6">
                            <div class="infoField">公司名称(英文)</div>
                            <div class="infoData">{{ client.clientNameEn }}</div>
                        </div>
                  </div>
                  <div class="row">
                        <div class="col-md-6">
                            <div class="infoField">所属行业</div>
                            <div class="infoData">{{ industry }}</div>
                        </div>
                        <div class="col-md-6">
                            <div class="infoField">客户类型</div>
                            <div class="infoData">{{ companyType }}</div>
                        </div>
                  </div>
                  <div class="row">
                        <div class="col-md-6">
                            <div class="infoField">客户来源</div>
                            <div class="infoData">{{ clientSource }}</div>
                        </div>
                        <div class="col-md-6">
                            <div class="infoField">标签</div>
                            <div class="infoData">
                              <div class="d-flex gap-2">
                                  <a href="#" class="badge badge-soft-primary" v-for="tag in clientTags" :key="client.clientId+tag">{{tag}}</a>
                              </div>
                            </div>
                        </div>
                  </div>
                  <div class="row">
                        <div class="col-md-12">
                            <div class="infoField">简介</div>
                            <div class="infoData">{{ client.profile }}</div>
                        </div>
                  </div>
                  <div class="row">
                        <div class="col-md-6">
                            <div class="infoField">地址(当地语言)</div>
                            <div class="infoData">{{ client.address }}</div>
                        </div>
                        <div class="col-md-6">
                            <div class="infoField">地址(英文)</div>
                            <div class="infoData">{{ client.addressEn }}</div>
                        </div>
                  </div>
                  <hr class="my-4">
                  <div class="row">
                        <div class="col-md-6"><div class="infoField">联系人列表</div></div>
                        <div class="col-md-6">
                          <div class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                            <a style="cursor: pointer;" class="btn btn-light" @click="handleNewContact"><i class="uil uil-plus me-1"></i> 新增</a>
                          </div>
                        </div>
                  </div>                  
                  <div class="row" v-show="!showContactForm">
                        <div class="col-md-12">
                            <div class="infoData">
                              <table class="table table-striped mb-0">
                                <thead>
                                  <tr>
                                    <th>联系人</th>
                                    <th>部门</th>
                                    <th>职位</th>
                                    <th>电话</th>
                                    <th>邮箱</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="contact in client.contacts" :key="contact.contactId">
                                    <td><a href="javascript:;" @click="handleEditContact(contact)">{{contact.contact}}</a></td>
                                    <td>{{contact.department}}</td>
                                    <td>{{contact.title}}</td>
                                    <td>{{contact.mobile}}</td>
                                    <td><a :href="'mailto:'+contact.email">{{contact.email}}</a></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                        </div>
                  </div>
                  <div class="row" v-show="showContactForm">
                    <div class="card border shadow-none mb-5">
                      <div class="card-body">
                          <div>
                              <div class="row">
                                  <div class="col-lg-6 mb-3">
                                          <label class="form-label" for="gen-info-contact-input">联系人</label>
                                          <input type="text" class="form-control" id="gen-info-contact-input" placeholder="请输入联系人" v-model="contactForm.contact">
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-lg-6 mb-3">
                                          <label class="form-label" for="gen-info-department-input">部门</label>
                                          <input type="text" class="form-control" id="gen-info-department-input" placeholder="请输入部门" v-model="contactForm.department">
                                  </div>
                                  <div class="col-lg-6">
                                      <div class="mb-3">
                                          <label class="form-label" for="gen-info-title-input">职位</label>
                                          <input type="text" class="form-control" id="gen-info-title-input" placeholder="请输入职位" v-model="contactForm.title">
                                      </div>
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-lg-6 mb-3">
                                          <label class="form-label" for="gen-info-mobile-input">手机号码</label>
                                          <input type="text" class="form-control" id="gen-info-mobile-input" placeholder="请输入联系人手机号码" v-model="contactForm.mobile">
                                  </div>
                                  <div class="col-lg-6">
                                      <div class="mb-3">
                                          <label class="form-label" for="gen-info-email-input">邮箱地址</label>
                                          <input type="text" class="form-control" id="gen-info-email-input" placeholder="请输入联系人邮箱地址" v-model="contactForm.email">
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div class="text-end">
                      <button type="button" class="btn btn-primary w-sm" @click="handleSaveContact">保存</button>
                      <button type="button" class="btn btn-success w-sm ms-1" @click="handleCancleContact">返回</button>
                  </div>                    
                  </div>
                </div>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">订单</span>
                </template>
                <order v-bind:show-title="false" :client-no="this.clientNo"></order>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-envelope"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">服务记录</span>
                </template>
                <service v-bind:show-title="false"></service>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-cog"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">开票信息</span>
                </template>
                <invoice></invoice>
              </b-tab>
            </b-tabs>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
<style lang="scss" scoped>
.infoField{
  font-weight: bold;
}
.infoData{
  min-height: 30px;
}
</style>